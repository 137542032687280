import React from 'react';

function AboutMeSection(){
  return (
    <section className="about-me" id="aboutme">
      <div className="container">
          <div className="col-lg-4">
          </div>
          <div className="col-lg-8">
            <h1>About Me</h1>
            <p>My name is Guru Swarupa aka M.S.G Swarupa and I'm a programmer based in Bengaluru.</p>
            <p>I am a Tech Enthusiast and have been exploring different fields such as Operating Systems mainly Linux and Windows, programming, Python, C, C++, Web development including HTML, CSS, Javascript, bootstrap, JQuery, NodeJS , Data Structures and Algorithms, Programming principles, project management and more.</p>
            <p> Few of my hobbies include exercising and working out, drawing and painting, learning new technologies and gadgets, tweaking operating systems, and watching courses.</p>
            <p>If you'd like to connect with me further, please feel free to follow me on social media. I look forward to hearing from you!</p>
          </div>
        </div>
        <div id="social-media-footer">
          <ul>
            <li>
              <a href="https://www.instagram.com/acexswaroop/">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/guru-swarupa-81a0a8262/">
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a href="https://github.com/ACExSWAROOP/">
                <i className="fab fa-github"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/acexswaroop">
                <i className="fab fa-twitter"></i>
              </a>
            </li>
          </ul>
        </div>
    </section>
  );
};

export default AboutMeSection;
