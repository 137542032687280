import React from 'react';

function IntroductionSection(){
  return (
    <section className="introduction">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-10 col-sm-12">
            <div className="profile-picture">
              <img src="images/IMG_0581edited.png" alt="Guru Swarupa" />
            </div>
          </div>
          <div className="col-lg-8 col-md-10 col-sm-12">
            <div className="content">
              <h1>Welcome</h1>
              <p>
                Explore my personal website and portfolio, where you can learn more about me, my artistic journey, and the projects I have undertaken. Get inspired and connect with me!
              </p>
              <div className="currplaying">
                <iframe style={{ borderRadius: '12px' }} src="https://spotify-nowplaying-acexswaroop.vercel.app/api/spotify" title="Current Playing"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroductionSection;