import React from 'react';

const ArtGallery = ({ images }) => {
  return (
    <div>
    <div className="heading" id="artgallery">
        <h2>Art Gallery</h2>
    </div>
    <div className="artgallery-container">
      {images.map((src, index) => (
        <div className="artgallery-item" key={index}>
          <img src={src} alt={`Artwork ${index + 1}` } loading="lazy"/>
        </div>
      ))}
    </div>
    </div>
  );
};

export default ArtGallery;
