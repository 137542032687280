import React from 'react';

const navStyles = {
    bottom: "20px",
    right: "20px", 
    position: "fixed",
    zIndex: "1"
};

function Navigation({ goToPrev, goToNext }) {
    return (
        <div style={navStyles} className="navigation-buttons strikingly-fixed">
            <span className="prev" onClick={goToPrev}></span>
            <span className="next" onClick={goToNext}></span>
        </div>
    );
}

export default Navigation;
