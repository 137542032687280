import React from 'react';

function ServicesSection()  {
  return (
    <section className="services" id="services">
      <div className="container">
        <h2>Services</h2>
        <div className="service-item">
          <i className="fas fa-code"></i>
          <h3>Web Design & Development</h3>
          <p>I create modern and responsive websites using HTML, CSS, and JavaScript.</p>
        </div>
        <div className="service-item">
          <i className="fas fa-mobile-alt"></i>
          <h3>Responsive Design</h3>
          <p>I ensure that your website looks great on all devices, from desktops to mobiles.</p>
        </div>
        <div className="service-item">
          <i className="fas fa-cogs"></i>
          <h3>Custom Web Applications</h3>
          <p>I develop custom web applications tailored to your specific business needs.</p>
        </div>
        <div className="service-item">
          <i className="fas fa-database"></i>
          <h3>Database Integration</h3>
          <p>I integrate databases to store and manage data for your web applications.</p>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
