import React from 'react';

function ProjectsSection(){
  return (
    <section className="projects" id="myprojects">
      <div className="container">
        <h2>My Projects</h2>
        <div className="row">
          {/* Project 1 */}
          <div className="col-xl-4 col-lg-12 col-md-12">
            <div className="project-item">
              <img src="images/s,c,t management splash dark.png" alt="Project 1" />
              <h3>Attendance Register</h3>
              <p>
                A Graphical user interface app for noting down attendance of students in institutions like school, college and coaching centres.
              </p>
              <a href="https://github.com/ACExSWAROOP/School-management-system" className="btn btn-primary">View Project</a>
            </div>
          </div>
          {/* Project 2 */}
          <div className="col-xl-4 col-lg-12 col-md-12">
            <div className="project-item">
              <img src="images/soft.png" alt="Project 2" />
              <h3>Softhub</h3>
              <p>
                Softhub is a GUI package manager to install other apps in a single click, that helps you to save time.
              </p>
              <a href="https://github.com/ACExSWAROOP/Softhub-lite" className="btn btn-primary">View Project</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;
