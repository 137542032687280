import React from 'react';
import { createRoot } from 'react-dom/client';
import Navbar from './components/navbar';
import IntroductionSection from './components/IntroductionSection';
import AboutMeSection from './components/AboutMeSection';
import ResumeSection from './components/ResumeSection';
import ServicesSection from './components/ServicesSection';
import ProjectsSection from './components/ProjectsSection';
import ArtGallery from './components/ArtGallery';
import PhotoGallery from './components/photogallery';
import Navigation from './components/navigation';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

const artimages = [
    'images/img_3_1658631297862.jpg',
    'images/img_2_1658631187736.jpg',
    'images/img_1_1658631150806.jpg',
    'images/img_6_1658631404639.jpg',
    'images/img_4_1658631341390.jpg',
    'images/IMG-20220731-WA0000.jpg',
    'images/IMG-20190220-WA0000.jpg',
    'images/IMG_20191124_103640.jpg',
    'images/IMG_20191214_152114.jpg',
    'images/IMG_20191215_123833.jpg',
    'images/IMG_20191224_180514.jpg',
    'images/IMG_20191224_180416.jpg',
    'images/IMG_20191224_180054.jpg',
    'images/IMG_20200107_174116.jpg',
    'images/IMG_20230325_234838_842.jpg',
    'images/Screenshot_20230409-195107_Instagram.jpg',
    'images/Screenshot_20230409-195125_Instagram.jpg',
  ];

const photoimages = [ 
    "images/IMG-20200515-WA0003.jpg",
    "images/Screenshot_2018-10-18-17-35-16-1.png",
    "images/IMG_9404.jpg",
    "images/2016-02-28 09.18.10.jpg",
    "images/20210110_172554.jpg",
    "images/20210110_194025.jpg",
    "images/20210110_194006.jpg",
    "images/20210315_230651.jpg",
    "images/20210626_184144.jpg",
    "images/20210908_213505.jpg",
    "images/20221114_085802.jpg",
    "images/20221122_133752.jpg",
    "images/20221122_134018.jpg",
    "images/20221225_161106.jpg",
    "images/20230216_094648.jpg",
    "images/20230216_095046.jpg",
    "images/20230216_095732.jpg",
    "images/20230216_101341(0).jpg",
    "images/20230216_121657.jpg",
    "images/20230216_155444.jpg",
    "images/20230216_155538.jpg",
    "images/20230216_160427.jpg",
    "images/20230216_160340.png",
    "images/20230216_160802.jpg",
    "images/DSC_0176.JPG",
    "images/DSC_0233.JPG",
    "images/DSC_0235.JPG",
    "images/DSC_0507.JPG",
    "images/DSC_0509.JPG",
    "images/FB_IMG_1448016714575.jpg",
    "images/FB_IMG_1644203408042.jpg",
    "images/FB_IMG_1644203425385.jpg",
    "images/IMG_0093.JPG",
    "images/IMG_7328.jpg",
    "images/IMG_0468.JPG",
    "images/IMG_0581.JPG",
    "images/IMG_0806~2.jpg",
    "images/IMG_2576.JPG",
    "images/IMG_2672.JPG",
    "images/IMG_2807.JPG",
    "images/IMG_2910.JPG",
    "images/IMG_9383.jpg",
    "images/IMG_20200507_161947.jpg",
    "images/IMG_9521.jpg",
    "images/IMG_9587.jpg",
    "images/IMG_9698.jpg",
    "images/IMG_20201004_190944_937.jpg",
    "images/IMG_20210426_144043_344.jpg",
    "images/IMG_20221228_172007_133.jpg",
    "images/IMG_20230126_172908_409.jpg",
    "images/IMG_20230216_213907_864.jpg",
    "images/IMG_20230219_195826_812.jpg",
    "images/IMG_7343.jpg",
    "images/IMG-20190117-WA0001-1.jpg",
    "images/IMG-20190217-WA0016~2.jpg",
    "images/IMG-20191109-WA0020.jpg",
    "images/IMG-20191220-WA0002.jpg",
    "images/IMG-20200606-WA0002.jpg",
    "images/IMG-20200606-WA0005.jpg",
    "images/IMG-20200706-WA0005.jpg",
    "images/IMG-20201103-WA0000.jpg",
    "images/IMG-20210107-WA0000.jpg",
    "images/IMG-20210111-WA0023.jpg",
    "images/IMG-20210111-WA0024.jpg",
    "images/IMG-20210111-WA0047.jpg",
    "images/IMG-20210118-WA0016.jpg",
    "images/IMG-20210211-WA0009.jpg",
    "images/IMG-20210211-WA0011.jpg",
    "images/IMG_7355.jpg",
    "images/IMG-20230117-WA0019.jpg",
    "images/IMG-20230216-WA0012.jpg",
    "images/IMG-20230216-WA0013.jpg",
    "images/Polish_20210426_125447092.jpg",
    "images/Screenshot_20221009-205107_Instagram.jpg",
    "images/Screenshot_20220708-164318_Instagram.jpg",
    "images/Snapchat-1559112794.jpg",
    "images/Snapchat-1576189745.jpg",
    "images/IMG_20221106_131639_989.jpg",
    "images/yercaud.gif",
    "images/20230216_130745.jpg",
    "images/20190517_180516.jpg",
    "images/20201214_123741.jpg",
    "images/20221114_143351.jpg",
    "images/20221122_134052.jpg",
    "images/20230216_134713.jpg",
    "images/20230216_161757.jpg",
    "images/croppedImage.png",
    "images/DSC_0137.JPG",
    "images/DSC_0109.jpg",
    "images/FB_IMG_1644203354544.jpg",
    "images/FB_IMG_1644203372460.jpg",
    "images/FB_IMG_1644203395970.jpg",
    "images/FB_IMG_1653150289287.jpg",
    "images/IMG_0157.JPG",
    "images/IMG_0199.JPG",
    "images/IMG_0591.JPG",
    "images/IMG_0607.JPG",
    "images/IMG_0608.JPG",
    "images/IMG_0609.JPG",
    "images/IMG_0612.JPG",
    "images/IMG_0808.jpg",
    "images/IMG_7330.jpg",
    "images/IMG_0846.jpg",
    "images/IMG_0861.jpg",
    "images/IMG_20221114_165442_828.jpg",
    "images/IMG_20230111_153826.jpg",
    "images/IMG_20230111_153933.jpg",
    "images/IMG_20230217_191823_819.jpg",
    "images/IMG-20180208-WA0012.jpg",
    "images/IMG-20181102-WA0018.jpg",
    "images/IMG-20190223-WA0006.jpg",
    "images/IMG-20200606-WA0003.jpg",
    "images/IMG-20210118-WA0014.jpg",
    "images/IMG-20210118-WA0015.jpg",
    "images/IMG-20221114-WA0011.jpg",
    "images/IMG-20221114-WA0014.jpg",
    "images/IMG-20221120-WA0003.jpg",
    "images/IMG_7357.jpg",
    "images/IMG-20230206-WA0001.jpg",
    "images/IMG-20230206-WA0007.jpg",
    "images/IMG-20230206-WA0009.jpg",
    "images/IMG-20230216-WA0020.jpg",
    "images/IMG-20230217-WA0003.jpg",
    "images/IMG-20230218-WA0000.jpg",
    "images/IMG20230216095515.jpg",
    "images/IMG20230216100821.jpg",
    "images/IMG20230216101126.jpg",
    "images/IMG20230216123159.jpg",
    "images/IMG20230216161401.jpg",
    "images/Screenshot_20221230-223130_Photos.jpg",
    "images/Screenshot_20230112-013529_Gallery.jpg",
    "images/IMG_9493.jpg",
    "images/IMG_9454.jpg",
    "images/IMG_9455-1.jpg",
    "images/IMG_0069.JPG",
    "images/IMG_0048.JPG",
    "images/IMG_0069.JPG",
    "images/IMG_0075.JPG",
    "images/IMG_0081.JPG",
    "images/IMG_0086.JPG",
    "images/IMG_0097.JPG",
    "images/IMG_0112.JPG",
    "images/IMG_0124.JPG",
    "images/IMG_0143.JPG",
    "images/IMG_0167.JPG",
    "images/IMG_0169.JPG",
    "images/IMG_0193.JPG",
    "images/IMG_0202.JPG",
    "images/IMG_0211.JPG",
    "images/IMG_7329.jpg",
    "images/IMG_0270.JPG",
    "images/IMG_0303.JPG",
    "images/IMG_0307.JPG",
    "images/IMG_0309.JPG",
    "images/IMG_0325.JPG",
    "images/IMG_0327.JPG",
    "images/IMG_0342.JPG",
    "images/IMG_0343.JPG",
    "images/IMG_0352.JPG",
    "images/20220424_061101.jpg",
    "images/20220424_061914.jpg",
    "images/20221002_162123.jpg",
    "images/DSC_0199.JPG",
    "images/DSC_0283.JPG",
    "images/DSC_0309.JPG",
    "images/DSC_0312.JPG",
    "images/DSC_0326.JPG",
    "images/DSC_0327.JPG",
    "images/DSC_0347.JPG",
    "images/DSC_0351.JPG",
    "images/DSC_0410.JPG",
    "images/DSC_0411.JPG",
    "images/DSC_0433.JPG",
    "images/DSC_0434.JPG",
    "images/DSC_0466.JPG",
    "images/DSC_0489.JPG",
    "images/DSC_0506.JPG",
    "images/DSC_0518.JPG",
    "images/DSC_0528.JPG",
    "images/DSC_0539.JPG",
    "images/DSC_0561.JPG",
    "images/IMG20220423134206.jpg",
    "images/IMG20220423180051.jpg",
    "images/IMG20220424101304.jpg",
    "images/IMG20220424101623.jpg",
    "images/IMG20220424101542.jpg",
    "images/IMG20220424101639.jpg",
    "images/IMG_7370.jpg",
    "images/IMG_7391.jpg",
    "images/IMG_7392.jpg",
    "images/IMG_7393.jpg",
    "images/IMG_0839.JPG",
    "images/IMG_0848.JPG",
    "images/IMG_0857.JPG",
    "images/IMG_0859.JPG",
    "images/IMG_0862.JPG",
    "images/IMG_0864.JPG",
    "images/IMG_0866.JPG",
    "images/IMG_0867.JPG",
    "images/IMG_0869.JPG",
    "images/IMG_0870.JPG",
    "images/IMG_0871.JPG",
    "images/IMG_0872.JPG",
    "images/IMG_0873.JPG",
    "images/IMG_0874.JPG",
    "images/IMG_0875.JPG",
    "images/IMG_0877.JPG",
    "images/IMG_0878.JPG",
    "images/IMG_0879.JPG",
    "images/IMG_0881.JPG",
    "images/IMG_0882.JPG",
    "images/IMG_0885.JPG",
    "images/IMG_0887.JPG",
    "images/IMG_0890.JPG",
    "images/IMG_0891.JPG",
    "images/IMG_0892.JPG",
    "images/IMG_0893.JPG",
    "images/IMG_0894.JPG",
    "images/IMG_0895.JPG",
    "images/IMG_0896.JPG",
    "images/IMG_0897.JPG",
    "images/IMG_0899.JPG",
    "images/IMG_0900.JPG",
    "images/IMG_0901.JPG",
    "images/IMG_0902.JPG",
    "images/IMG_0903.JPG",
    "images/IMG_0904.JPG",
    "images/IMG_0905.jpg",
    "images/IMG_0907.JPG",
    "images/IMG_0908.JPG",
    "images/IMG_0909.JPG",
    "images/IMG_0910.JPG",
    "images/IMG_0911.JPG",
    "images/IMG_0912.JPG",
    "images/IMG_0913.JPG",
    "images/IMG_0915.JPG",
    "images/IMG_0916.JPG",
    "images/IMG_0917.JPG",
    "images/IMG_0918.JPG",
    "images/IMG_0920.jpg",
    "images/IMG_0921.JPG",
    "images/IMG_0922.JPG",
    "images/IMG_0923.JPG",
    "images/IMG_0924.JPG",
    "images/IMG_20230606_225111_523.jpg"
];

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <div>
    <Navbar />
    <Navigation />
    <IntroductionSection />
    <AboutMeSection />
    <ResumeSection />
    <ServicesSection />
    <ProjectsSection />
    <ArtGallery images={artimages} />
    <PhotoGallery images={photoimages} />
  </div>);
