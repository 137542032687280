import React from 'react';

function ResumeSection() {
  return (
    <section className="resume" id="resume">
      <div className="container">
        <div className="profile">
          <img src="images/IMG_20230219_195826_812.jpg" alt="myimg" />
          <h2>Guru Swarupa</h2>
          <p>Tech Enthusiast</p>
        </div>
        <div className="content">
          <h3>Summary</h3>
          <p>
            As a passionate individual deeply fascinated by the world of computer science, I thrive on the endless possibilities and innovations this field offers. With a relentless drive to understand and create, I have immersed myself in the study of computer systems, algorithms, and programming languages. My journey in computer science has been one of exploration, experimentation, and continuous learning.
          </p>
          <p>
            With a strong foundation in the principles of computer science, I have honed my skills in coding and problem-solving, relishing the challenge of tackling complex issues with innovative solutions. The power of technology to transform lives and industries excites me, and I am committed to harnessing this potential to make a positive impact on the world.
          </p>
          <p>
            My experience in various projects and programming challenges has reinforced my belief in the importance of collaboration and teamwork. I cherish the opportunity to collaborate with like-minded individuals, exchanging ideas and pushing the boundaries of what is possible.
          </p>
          <p>
            My passion for computer science is not confined to academic pursuits; it extends to real-world applications. From developing innovative software solutions to exploring artificial intelligence and data science, I am driven to contribute meaningfully to cutting-edge projects that shape the future of technology.
          </p>
          <p>
            In all my endeavors, I approach challenges with curiosity, creativity, and a relentless desire to make a difference. My enthusiasm for computer science fuels my determination to be a lifelong learner and an agent of progress in this dynamic and ever-exciting field.
          </p> 
    
          <h3>Education</h3>
          <p>
            Class X , 88.4%<br />
            Vagdevi Vilas Institution, 2021
          </p>
          <p>
            Class XII , 80.8% <br />
            Vagdevi Vilas Institution, 2021
          </p>
    
          {/* Experience section */}
          {/* <p>
            Web Developer <br>
            Company Name - Start Date to End Date <br>
            - Developed and maintained client websites using HTML, CSS, and JavaScript. <br>
            - Collaborated with the design team to create visually appealing and functional websites.-->
          </p>*/}
    
          <h3>Skills</h3>

          <ul>
            <li>
                <h5>Languages</h5>
                    <ol>
                        <li>Python</li>
                        <li>JavaScript</li>
                        <li>C</li>
                        <li>C++</li>
                    </ol>
            </li>
            <li>
                <h5>Web Dev</h5>
                <ol>
                    <li>HTML5</li>
                    <li>CSS3</li>
                    <li>JQuery</li>
                    <li>Bootstrap</li>
                    <li>NodeJS</li>
                    <li>ExpressJS</li>
                    <li>API</li>
                    <li>EJS</li>
                    <li>MongoDB</li>
                    <li>Mongoose</li>
                    <li>React JS</li>
                </ol>
            </li>
            <li>
                <h5>Software</h5>
                <ol>
                    <li>Git</li>
                    <li>github</li>
                    <li>Linux</li>
                    <li>Project Management</li>
                    <li>Tkinter</li>
                    <li>MySQL</li>
                </ol>
            </li>
            <li>
                <h5>Others</h5>
                <ol>
                    <li>Project Management</li>
                </ol>
            </li>
        </ul>
    
          <h3>Contact</h3>
          <p>Email: msgswarupa@gmail.com</p>
          <p>Phone: +91 93532 38992</p>
        </div>
      </div>
    </section>
  );
};

export default ResumeSection;
