import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


const containerstyle = {
  margin : 0  //remove margin from Container div
}

function navbar() {
  return (
    <Navbar className='fixed-top' collapseOnSelect expand="lg">
      <Container style={containerstyle}>
        <Navbar.Brand href="#aboutme"><img src="images/Guru Swarupa.png" alt="logo" width="250" height="40" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
          <Nav.Link href="#root">Home</Nav.Link>
            <Nav.Link href="#aboutme">About Me</Nav.Link>
            <Nav.Link href="#resume"> Resume </Nav.Link>
            <Nav.Link href="#services">Services</Nav.Link>
            <Nav.Link href="#myprojects">My Projects</Nav.Link>
            <NavDropdown title="Gallery" id="basic-nav-dropdown">
              <NavDropdown.Item href="#artgallery">Art Gallery</NavDropdown.Item>
              <NavDropdown.Item href="#photogallery">Photo Gallery</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default navbar;
